import styled from 'styled-components';
import Button from 'components/shared/Button/Button.styled';
import { colors, transitions, breakpoints } from 'variables';

export const ListWrapper = styled.div`
   position: sticky;
   top: ${({ topOffset }) => `${topOffset}px`};
   transition: top ${transitions.ease};
`;

export const List = styled.ul`
   display: flex;
`;

export const ListItem = styled(Button)`
   font-size: 1.4rem;
   font-weight: 500;
   text-align: center;
   display: flex;
   align-items: center;
   border-radius: 0;
   padding: 0;

   &:first-of-type {
      border-right: none;
      border-bottom-left-radius: 6px;
      border-top-left-radius: 6px;
   }

   button {
      background: none;
      cursor: pointer;
      border: none;
      padding: 10px 12px;
      color: inherit;
      ${breakpoints.md`
      padding: 15px 20px;
   `}
      ${breakpoints.lg`
      padding: 20px 70px;
   `}
   }

   &:last-of-type {
      border-left: none;
      border-bottom-right-radius: 6px;
      border-top-right-radius: 6px;
   }

   &.active {
      color: ${colors.white};
      background-color: ${colors.brand};
   }

   &.unactive {
      color: ${colors.black};
      background: ${colors.white};
   }
`;

import styled from 'styled-components';
import { transitions, breakpoints } from 'variables';

export const SectionContainer = styled.div`
   display: flex;
   flex-direction: column;
   padding-top: 60px;
   width: 100%;

   button {
      width: fit-content;
      margin-top: 10px;
      align-self: center;

      img {
         margin-left: 10px;
         transition: filter ${transitions.easeInOut};
      }

      &:hover {
         img {
            filter: brightness(0) invert(1);
         }
      }
   }

   ${breakpoints.md`
      padding-top: 80px;

      button { margin-top: 40px; }
   `};

   ${breakpoints.lg`
      padding-top: 160px;

      &:first-of-type {
         padding-top: 80px;
      }
   `};
`;

export const ColumnsContainer = styled.ul`
   height: 100%;
   max-height: ${({ scrollHeight, isFullColumn }) =>
      isFullColumn ? scrollHeight : scrollHeight / 2}px;
   transition: max-height ${transitions.easeInOutSlow};
   overflow: hidden;
   display: grid;
   max-width: 100%;
   gap: 20px 20px;
   justify-content: center;
   margin-top: 45px;
   flex-direction: column;

   ${breakpoints.md`
      margin-top: 60px;
      grid-template-columns: 1fr
      padding: 0 50px;
      max-height: ${({ scrollHeight, isFullColumn }) =>
         isFullColumn ? scrollHeight : scrollHeight / 2}px;
      `}

   ${breakpoints.lg`
      grid-template-columns: repeat(2, 1fr);
      display: grid;
      gap: 45px 20px;
      margin-top: 70px;
      
      
      max-height: ${({ scrollHeight, isFullColumn }) =>
         isFullColumn ? scrollHeight : scrollHeight / 3}px;
         `}
   ${breakpoints.xl`

      grid-template-columns: repeat(3, 1fr);
      display: grid;
      gap: 45px 20px;
      margin-top: 70px;

      max-height: ${({ scrollHeight, isFullColumn }) =>
         isFullColumn ? scrollHeight : scrollHeight / 2}px;
   `}
`;

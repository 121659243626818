import { useNavbarContext } from 'contexts/NavbarContext';
import React from 'react';
import scrollTo from 'utilities/scrollTo';
import { List, ListItem, ListWrapper } from './ListNav.styled';

const ListNav = ({ sectionsLinks, activeSection }) => {
   const { isMenuVisible, navbarHeight } = useNavbarContext();

   return (
      <ListWrapper topOffset={isMenuVisible ? navbarHeight : 10}>
         <List>
            {sectionsLinks.map((item) => (
               <ListItem
                  as="li"
                  key={item.sectionID}
                  className={
                     item.sectionID === activeSection ? 'active' : 'unactive'
                  }
               >
                  <button onClick={() => scrollTo(item.sectionID)}>
                     {item.sectionLinkText}
                  </button>
               </ListItem>
            ))}
         </List>
      </ListWrapper>
   );
};

export default ListNav;

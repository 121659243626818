import styled from 'styled-components';
import { breakpoints } from 'variables';

export const ImgContainer = styled.div`
   height: 350px;
   display: flex;
   border-radius: 12px;
   justify-content: center;
   background-color: ${(props) => props.bgColor};
   img {
      max-width: 100%;
      display: block;
      align-self: flex-end;
      max-height: 300px;
      :first-of-type {
         max-height: unset;
      }
   }
   ${breakpoints.lg`
      height: 350px;
   `}
`;

export const RowContainer = styled.section`
   display: flex;
   justify-content: space-between;
   flex-direction: column;

   > div {
      flex: 50%;

      &:last-of-type {
         display: flex;
         align-items: center;
      }
   }

   ${breakpoints.md`
      flex-direction: ${({ reverseRow }) =>
         reverseRow ? 'row-reverse' : 'row'};

      > div {
         &:first-of-type {
            padding: ${({ reverseRow }) =>
               reverseRow ? '0 0 0 15px' : '0 15px 0 0'};
         }
         &:last-of-type {
            padding: ${({ reverseRow }) =>
               reverseRow ? '0 15px 0 0' : '0 0 0 15px'};
         }
      }
   `};

   ${breakpoints.lg`
      > div {
         &:first-of-type {
            padding: ${({ reverseRow }) =>
               reverseRow ? '0 0 0 45px' : '0 15px 0 0'};
         }
         &:last-of-type {
            padding: ${({ reverseRow }) =>
               reverseRow ? '0 15px 0 0' : '0 0 0 15px'};
         }
      }
   `}
`;

export const TextContainer = styled.div`
   display: flex;
   flex-direction: column;
   justify-content: center;

   p {
      font-weight: 300;
      line-height: 24px;
   }

   h2 {
      font-weight: 300;
      margin-top: 16px;

      strong {
         font-weight: 700;
      }
   }

   ${breakpoints.lg`
      p {
         padding-right: 100px;
         line-height: 28px;
      }

      h2 {
         strong { display: block; }
      }
   `}
`;

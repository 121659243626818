import React, { Fragment } from 'react';
import Badge from '../SectionBadge';
import { ImgContainer, RowContainer, TextContainer } from './SectionRow.styled';

const SectionRow = ({
   color,
   image,
   imageX2,
   tag,
   header,
   description,
   reverseRow,
}) => {
   return (
      <RowContainer reverseRow={reverseRow}>
         <TextContainer reverseRow={reverseRow}>
            <Badge bgColor={color}>{tag}</Badge>

            <h2>
               {header.map((text, i) => (
                  <Fragment key={i}>{text}</Fragment>
               ))}
            </h2>

            <p>{description}</p>
         </TextContainer>

         <ImgContainer bgColor={color}>
            <img src={image} srcSet={`${image} 1x, ${imageX2} 2x`} alt="" />
         </ImgContainer>
      </RowContainer>
   );
};

export default SectionRow;

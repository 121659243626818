import arrowDown from 'assets/icons/arrow_down_12.svg';
import Button from 'components/shared/Button';
import React, { useRef, useState } from 'react';
import FeatureColumn from './FeatureColumn';
import { ColumnsContainer, SectionContainer } from './Section.styled';
import SectionRow from './SectionRow';

const Section = (props) => {
   const columnsContainerRef = useRef();
   const [isFullColumn, setIsFullColumn] = useState(false);
   const {
      sectionData,
      sectionData: { features },
   } = props;

   const featuresList = features.map((feature, index) => (
      <FeatureColumn
         key={index}
         header={feature.header}
         description={feature.description}
         icon={feature.icon}
         image={feature.image}
         imageX2={feature.imageX2}
      />
   ));

   return (
      <SectionContainer id={sectionData.sectionID} className="functionality">
         <SectionRow
            color={sectionData.sectionColor}
            image={sectionData.image}
            imageX2={sectionData.imageX2}
            reverseRow={sectionData.reverseRow}
            tag={sectionData.tag}
            header={sectionData.header}
            description={sectionData.description}
         />

         {features.length > 3 ? (
            <>
               <ColumnsContainer
                  scrollHeight={columnsContainerRef?.current?.scrollHeight}
                  ref={columnsContainerRef}
                  isFullColumn={isFullColumn}
               >
                  {featuresList}
               </ColumnsContainer>
               <Button onClick={() => setIsFullColumn((prev) => !prev)}>
                  Pokaż {isFullColumn ? 'mniej' : 'więcej'}
                  <img
                     src={arrowDown}
                     alt="arrow"
                     style={{ transform: isFullColumn && 'rotate(180deg)' }}
                  />
               </Button>
            </>
         ) : (
            <ColumnsContainer className="three-items" ref={columnsContainerRef}>
               {featuresList}
            </ColumnsContainer>
         )}
      </SectionContainer>
   );
};

export default Section;

import ListNav from 'components/pages/functionalities/ListNav';
import Section from 'components/pages/functionalities/Section';
import Container from 'components/shared/Container';
import PageHeader from 'components/shared/PageHeader';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import scrollTo from 'utilities/scrollTo';
import functionalitiesData from 'data/functionalities';
import SEO from 'components/shared/SEO';

const StyledContainer = styled(Container)`
   flex-direction: column;
   align-items: center;
   margin-top: 50px;
`;

const Functionalities = ({ location }) => {
   const observerRef = useRef(null);
   const sectionId = location.state?.sectionId;

   const headerText = [
      'Planuj, realizuj zadania i raportuj ',
      <strong>wykonaną pracę</strong>,
   ];

   const [activeSection, setActiveSection] = useState('');

   useEffect(() => {
      const functionalityElements = document.querySelectorAll('.functionality');
      observerRef.current = new IntersectionObserver(
         (entriesList) => {
            entriesList.forEach((entry) => {
               if (entry.isIntersecting) {
                  setActiveSection(entry.target.id);
               }
            });
         },
         {
            rootMargin: '-100% 0px 0px',
         },
      );
      functionalityElements.forEach((ref) => {
         if (ref) {
            observerRef.current.observe(ref);
         }
      });
   }, []);

   useEffect(() => {
      if (!sectionId) {
         return;
      }
      const timeout = setTimeout(() => {
         scrollTo(sectionId);
      }, 100);

      return () => {
         clearTimeout(timeout);
      };
   }, [sectionId]);

   const sectionsLinks = functionalitiesData.map((section) => ({
      sectionID: section.sectionID,
      sectionLinkText: section.sectionLinkText,
   }));

   return (
      <>
         <SEO title="Funkcjonalności" />
         <PageHeader headerText={headerText} />

         <StyledContainer>
            <ListNav
               sectionsLinks={sectionsLinks}
               activeSection={activeSection}
               setActiveSection={setActiveSection}
            />

            {functionalitiesData.map((section, index) => {
               const { sectionLinkText: remove, ...rest } = section;

               return <Section key={index} sectionData={rest} />;
            })}
         </StyledContainer>
      </>
   );
};

export default Functionalities;

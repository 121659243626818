import React, { Fragment } from 'react';
import {
   ColumnContainer,
   TextContainer,
   ImageContainer,
} from './FeatureColumn.styled';

const FeatureColumn = ({ header, description, image, imageX2, icon }) => {
   return (
      <ColumnContainer>
         <TextContainer>
            <img src={icon} alt="icon" />

            <h4>
               {header.map((text, i) => (
                  <Fragment key={i}>{text}</Fragment>
               ))}
            </h4>

            <p>{description}</p>
         </TextContainer>

         <ImageContainer>
            <img src={image} srcSet={`${image} 1x, ${imageX2} 2x`} alt="" />
         </ImageContainer>
      </ColumnContainer>
   );
};

export default FeatureColumn;

import styled from 'styled-components';
import { breakpoints } from 'variables';

const Badge = styled.div`
   width: fit-content;
   padding: 10px 16px;
   background-color: ${({ bgColor }) => bgColor};
   font-weight: 500;
   font-size: 1.1rem;
   color: #211e4e;
   border-radius: 6px;
   text-transform: uppercase;

   ${breakpoints.md`
      font-size: 1.2rem;
   `}
`;

export default Badge;

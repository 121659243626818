import React from 'react';

import { colors } from 'variables';

import planningImg from 'assets/images/functionalities/image24.png';
import planningImgx2 from 'assets/images/functionalities/image24@2x.png';

import operationsImg from 'assets/images/functionalities/image25.png';
import operationsImgx2 from 'assets/images/functionalities/image25@2x.png';

import reportsImg from 'assets/images/functionalities/image26.png';
import reportsImgx2 from 'assets/images/functionalities/image26@2x.png';

import grafika1 from 'assets/images/functionalities/grafika1.png';
import grafika1x2 from 'assets/images/functionalities/grafika1@2x.png';
import grafika2 from 'assets/images/functionalities/grafika2.png';
import grafika2x2 from 'assets/images/functionalities/grafika2@2x.png';
import grafika3 from 'assets/images/functionalities/grafika3.png';
import grafika3x2 from 'assets/images/functionalities/grafika3@2x.png';
import grafika4 from 'assets/images/functionalities/grafika4.png';
import grafika4x2 from 'assets/images/functionalities/grafika4@2x.png';
import grafika5 from 'assets/images/functionalities/grafika5.png';
import grafika5x2 from 'assets/images/functionalities/grafika5@2x.png';
import grafika6 from 'assets/images/functionalities/grafika6.png';
import grafika6x2 from 'assets/images/functionalities/grafika6@2x.png';
import grafika7 from 'assets/images/functionalities/grafika7.png';
import grafika7x2 from 'assets/images/functionalities/grafika7@2x.png';
import grafika8 from 'assets/images/functionalities/grafika8.png';
import grafika8x2 from 'assets/images/functionalities/grafika8@2x.png';
import grafika9 from 'assets/images/functionalities/grafika9.png';
import grafika9x2 from 'assets/images/functionalities/grafika9@2x.png';
import grafika10 from 'assets/images/functionalities/grafika10.png';
import grafika10x2 from 'assets/images/functionalities/grafika10@2x.png';
import grafika11 from 'assets/images/functionalities/grafika11.png';
import grafika11x2 from 'assets/images/functionalities/grafika11@2x.png';
import grafika12 from 'assets/images/functionalities/grafika12.png';
import grafika12x2 from 'assets/images/functionalities/grafika12@2x.png';
import grafika13 from 'assets/images/functionalities/grafika13.png';
import grafika13x2 from 'assets/images/functionalities/grafika13@2x.png';
import grafika14 from 'assets/images/functionalities/grafika14.png';
import grafika14x2 from 'assets/images/functionalities/grafika14@2x.png';
import grafika15 from 'assets/images/functionalities/grafika15.png';
import grafika15x2 from 'assets/images/functionalities/grafika15@2x.png';

import planuj from 'assets/icons/functionalities/planuj_48.svg';
import przypisz from 'assets/icons/functionalities/przypisz_48.svg';
import szablon from 'assets/icons/functionalities/szablon_48.svg';
import dokumenty from 'assets/icons/functionalities/dokumenty_48.svg';
import plan from 'assets/icons/functionalities/plan_48.svg';
import cykliczne from 'assets/icons/functionalities/cykliczne_48.svg';
import alert from 'assets/icons/functionalities/alert_48.svg';
import forms from 'assets/icons/functionalities/forms_48.svg';
import microscope from 'assets/icons/functionalities/microscope_48.svg';
import history from 'assets/icons/functionalities/history_48.svg';
import search from 'assets/icons/functionalities/search_48.svg';
import filter from 'assets/icons/functionalities/filter_48.svg';
import report from 'assets/icons/functionalities/report_48.svg';
import report2 from 'assets/icons/functionalities/report2_48.svg';
import report3 from 'assets/icons/functionalities/report3_48.svg';

export default [
   {
      sectionColor: colors.lightBlue,
      sectionID: 'functionalities-reception',
      image: planningImg,
      imageX2: planningImgx2,
      reverseRow: false,
      tag: 'planowanie',
      sectionLinkText: 'Planowanie',
      header: [<strong>Zaplanuj</strong>, ' pracę swojemu zespołowi'],
      description:
         'Umieszczaj zlecenia, projekty, zadania i podzadania w harmonogramie. Organizuj pracę dla każdego pracownika z osobna na każdy dzień.',
      features: [
         {
            icon: planuj,
            header: [
               <strong>Planuj zadania</strong>,
               ' przewidziane na dzień, tydzień lub miesiąc',
            ],
            description:
               'Umieszczaj zadania do realizacji i zapełnij harmonogram pracy na tydzień lub nawet miesiąc do przodu.',
            image: grafika1,
            imageX2: grafika1x2,
         },
         {
            icon: przypisz,
            header: [
               <strong> Przypisuj</strong>,
               ' poszczególne zadania do ',
               <strong> konkretnych pracowników</strong>,
            ],
            description:
               'Żadne zadanie nie zaginie jeśli ma dedykowanego opiekuna w systemie.',
            image: grafika2,
            imageX2: grafika2x2,
         },
         {
            icon: szablon,
            header: [
               <strong>Twórz samodzielnie</strong>,
               ' szablony do pracy operacyjnej',
            ],
            description:
               'Zmień stare metody działania na nowe poprzez wybór elestycznego kreatora własnego szablonu do każdego zadania.',
            image: grafika3,
            imageX2: grafika3x2,
         },
         {
            icon: dokumenty,
            header: [
               <strong>Stwórz własne bazy </strong>,
               'dokumentów, klientów, zleceń',
            ],
            description:
               'Wrzuć do systemu każdy dokument, powtarzalne zlecenia, dane klienta.',
            image: grafika4,
            imageX2: grafika4x2,
         },
         {
            icon: plan,
            header: [
               'Planuj zadania i ',
               <strong>przyporządkuj dla nich podzadania</strong>,
            ],
            description:
               'Twórz zadania i podzadania w harmonogramie w momencie gdy projekt rozrasta się wraz z jego realizacją. ',
            image: grafika5,
            imageX2: grafika5x2,
         },
         {
            icon: cykliczne,
            header: [
               'Nie trać czasu na ',
               <strong>organizację zadań cyklicznych</strong>,
            ],
            description:
               'Ustaw w systemie częstotliwość wykonania zadań, które regularnie się powtarzają.',
            image: grafika6,
            imageX2: grafika6x2,
         },
      ],
   },
   {
      sectionColor: colors.lightViolet,
      sectionID: 'functionalities-operations',
      image: operationsImg,
      imageX2: operationsImgx2,
      reverseRow: true,
      tag: 'operacje',
      sectionLinkText: 'Operacje',
      header: [<strong>Realizuj zadania</strong>, ' przy pomocy systemu'],
      description:
         'Głównym celem systemu jest ochrona i wsparcie zadań operacyjnych w Twojej firmie. Przenieś ich obsługę na poziom wyżej - w świat cyfrowego porządku i udogodnień.',
      features: [
         {
            icon: alert,
            header: [
               <strong>Inteligentne powiadomienia -</strong>,
               '  trakuj postępy pracy i błędy',
            ],
            description:
               'Jedn rzut oka wystarczy, by skontrolować status wykonywanych zadań.',
            image: grafika7,
            imageX2: grafika7x2,
         },
         {
            icon: forms,
            header: [
               <strong>Formularze elektroniczne -</strong>,
               ' wyeliminuj zeszyty do wprowadzania wyników',
            ],
            description:
               'Każdy pracownik w formularzu zadania będzie widział historię wyników nawet do sześciu okresów wstecz.',
            image: grafika8,
            imageX2: grafika8x2,
         },
         {
            icon: microscope,
            header: [
               <strong>Bazy zasobów firmy -</strong>,
               ' pilnuj stanu sprzętów i urządzeń firmowych',
            ],
            description:
               'Otrzymuj alerty systemowe o zbliżającycm się przeglądzie, badaniu technicznym, lub konieczności odnowienia badań.',
            image: grafika9,
            imageX2: grafika9x2,
         },
         {
            icon: history,
            header: [
               <strong>Śledź historię każdego</strong>,
               ' realizowanego zadania w twojej firmie',
            ],
            description:
               'Dzięki historii zadań łatwo dojdziesz kto stoi za ewentualnym błędem.',
            image: grafika10,
            imageX2: grafika10x2,
         },
         {
            icon: search,
            header: [
               <strong>Wyszukiwarka</strong>,
               ' - szybko znajdziesz informacje których potrzebujesz',
            ],
            description:
               'W łatwy sposób przeszukaj system po zadaniach i podzadaniach.',
            image: grafika11,
            imageX2: grafika11x2,
         },
         {
            icon: filter,
            header: [
               <strong>Twórz własne filtry</strong>,
               ' do zawężania wyników wyszukiwań',
            ],
            description:
               'Tworząc szablony sam decydujesz o tym w jaki sposób będzie filtrowana twoja przyszła lista zadań w systemie.',
            image: grafika12,
            imageX2: grafika12x2,
         },
      ],
   },
   {
      sectionColor: colors.lightGreen,
      sectionID: 'functionalities-reports',
      image: reportsImg,
      imageX2: reportsImgx2,
      reverseRow: false,
      tag: 'raportowanie',
      sectionLinkText: 'Raportowanie',
      header: ['Zarządzaj ', <strong>efektywnością</strong>],
      description:
         'Chcesz wiedzieć, czy zadania realizowane są optymalnie? Wszystkie dane niezbędne do tworzenia raportów znajdziesz w jednym miejscu. Wystarczy, że zaznaczysz odpowieni filtr czy zakres informacji, a raport dla Klienta, Sanepidu lub innego Zleceniodawcy wygeneruje się automatycznie.',
      features: [
         {
            icon: report,
            header: [
               <strong>Twórz raporty </strong>,
               ' w oparciu o dowolnie wybrane informacje',
            ],
            description:
               'Koniec z marnowaniem czasu na zbieranie danych do raport. Znajdź wszystko w jednym miejscu i stwórz raport',
            image: grafika13,
            imageX2: grafika13x2,
         },
         {
            icon: report2,
            header: [
               <strong>Zapisuj</strong>,
               ' ulubione lub najczęściej generowane raporty',
            ],
            description:
               'Automatyzuj pracę wszędzie gdzie to możliwe. Powtarzalne raporty zapisz jako ulubione i twórz je w ciągu kilku sekund.',
            image: grafika14,
            imageX2: grafika14x2,
         },
         {
            icon: report3,
            header: [
               <strong>Eksportuj</strong>,
               ' i zapisuj wybrane raporty do dowolnych formatów',
            ],
            description:
               'Wysyłaj raporty prosto z systemu. Format dopasuj do wymagań klienta',
            image: grafika15,
            imageX2: grafika15x2,
         },
      ],
   },
];

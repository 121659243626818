import styled from 'styled-components';
import { breakpoints } from 'variables';

export const ColumnContainer = styled.li`
   display: flex;
   flex-direction: column;
   justify-content: space-between;
   flex: 1;
   margin: 30px 0;

   &:first-of-type {
      margin-top: 0px;
   }

   ${breakpoints.md`
      margin: 0;
   `}
`;

export const TextContainer = styled.article`
   display: flex;
   flex-direction: column;
   align-items: flex-start;
   height: auto;
   h4 {
      margin-top: 10px;
      font-weight: 300;

      strong {
         font-weight: 700;
      }
   }

   p {
      margin-top: 18px;
      line-height: 26px;
      font-weight: 300;
   }

   ${breakpoints.lg`
      padding-right: 30px;

      h4 {
         margin-top: 20px;
         padding-right: 55px;

      }
   `}
`;

export const ImageContainer = styled.div`
   margin-top: 20px;
   display: flex;
   justify-content: center;

   img {
      height: 320px;
      width: 320px;
      ${breakpoints.xs`
         height: 340px;
         width: 340px;
      `}
      ${breakpoints.md`
         height: 350px;
         width: 350px;
      `}
   }

   ${breakpoints.lg`
      margin-top: 40px;
   `}
`;
